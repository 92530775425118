
.contact-bg01 {
    background: #000;
    padding: 80px 50px;
    border-radius: 10px;
    }
    .contact-bg01 h2{
       font-size: 35px;
    color: #fff;
    }
    .contact-bg01 .slider-btn {
        width: 100%;
    }
    
    .contact-bg01 .btn.ss-btn.active {
        background: #ED1E24;
        border: 2px solid #ED1E24;
        color: #fff;
    }
    .menu-area-update .menu-tigger {
        cursor: pointer;
        display: inline-block;
    }
    .menu-area-update .menu-tigger span {
        height: 2px;
        width: 30px;
        background: #fff;
        display: block;
        margin: 7px 0;
        transition: .3s;
    }
    .header-three .second-header {
        background: #07123d;
        padding: 5px 0px 0px 0px; 
      }
      .header-three .menu-area::before{
          display: none;
      }
      .header-three .menu-area {
      /* border-top: 0;
          position: relative;
      z-index: 9;
          box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1); */
          position: absolute;
          top: 60px;
          left: 0;
          background-color: rgba(0, 0, 0, 0.5);
          width: 100%;
          color: #fff;
      }
      .update-header-three .sticky-menu {
          position: absolute;
          top: 0px;
          left: 0;
          background-color: #fff;
          width: 100%;
          color: #fff;
      }
      
      .update-header-three .main-menu ul li a {
              font-family: system-ui;
              font-size: 17px;
          color:#000;
      }
      .update-header-three .main-menu .sub-menu li a,.menu .children li a {
          color: #000 !important;
      }
      .update-header-three .main-menu .sub-menu li a:hover,.menu .children li a:hover{
          color: #ED1E24 !important;
      }
      .btn.ss-btn {
          float: right;
      }
      .update-copyright-wrap {
        background: #fff;
        color: #000;
        font-size: 15px;
    }
    .update-copyright-wrap .container{
        padding: 20px 0;
    }
    .update-copyright-wrap li {
        display: inline;
        padding-left: 15px;
        margin-left: 40px;
        position: relative;
    }
    .update-copyright-wrap li::before {
    background-color: #141b22;
    width: 8px;
    height: 8px;
    content: "";
    position: absolute;
    left: -4px;
    top: 5px;
    border-radius: 50px;
    }
    .update-copyright-wrap li a{
        color: #fff;
    }
    .update-f-contact {
        display: flex;
        }
        .update-f-contact i {
          width: 40px;
          height: 40px;
        background: #ED1E24;
          line-height: 40px;
          color: #fff !important;
          text-align: center;
          border-radius: 50%;
          float: left;
        }
        
        
        .update-f-contact a{
            color:#fff !important;
        }
        .update-f-contact a:hover{
            color: #fffc!important;
        }
        .update-f-contact span{
            /* left: 10vh; */
            color:#fff;
        }
        .update-f-contact h3 {
            color: #fff;
            font-size: 24px;
        }
        .update-f-contact .icon {
            float: left;
            margin-top: -5px;
            margin-right: 15px;
            width: 40px;
        }
        
        .update-f-contact li {
            float: left;
            margin-bottom: 15px !important;
            width: 100%;
        }
        .update-f-contact li:last-child{
            margin-bottom: 0px !important;
        }
        
/*/////////////////////////////////////////// About Screen class/////////////////*/
               .update-s-about-content p {
                margin-bottom: 15px;
                /* color: black; */
                font-size: 18px;
                font-family: Google Sans;
                /* font-weight: 600; */
            }

            /*////////////////////////////////////// Prgram screen class ////////////////*/
            .update-courses-content {
                padding: 30px;    
                float: left;
                position: relative;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                border-radius: 4px;
                
            }
            .update-courses-content h4{
                font-size: 26px;
                margin-bottom: 15px;
            }
            .update-courses-content .schedule span {
              display: block;
              line-height: 22px;
              color: #12265a;
            }
            .update-courses-content a{
                color: #000;
                font-family: Georgia;
            }
            
            .update-courses-content a.readmore{
                 color: #ED1E24;
            }
            .update-courses-content a.readmore:hover{
                 color: #141b22;
            }
            .update-courses-content a:hover{
                color: #ED1E24;
            }
           
            .update-testimonial-para{
                scrollbar-width: thin;
                /* background-color: lightgreen; */
                height: 150px;
                padding-right: 10px;
                /* width: 200px; */
                overflow-y: scroll;
                  text-align: justify;
                  /* color: #000;  */
                  /* font-family: 'system-ui'; */
                  font-family: Georgia;
                  font-size: 17px;
                  color: #000;
            }
            .update-testimonial-para::-webkit-scrollbar {
                width: 4px;
            }

            /*////////////////////////////////// Shep screen css/////////////////*/
            .update-steps-area .section-title  p{
                color: #fff;
                font-size: 16px;
                margin-top: 15px;
                font-family: Georgia;
            }
            .update-steps-area h2,.update-steps-area h3{
                color: #fff;
                font-family: Georgia;
                font-size: 28px;
                margin-top: 10px;
            }
/*//////////////////////////////// Finacial Aid screen css //////////////////*/
.update-cta-bg {
	position: relative;
	background-size: cover;
	background-position: center;
	z-index: 1;
    font-family: Georgia;
}
.update-cta-title.video-title h2{
    font-size: 68px;
}
.update-cta-title h2 {
  padding-bottom: 0;
  font-size: 50px;
color: #fff;
}
.update-cta-title h2 span{
    color: #ED1E24;
    text-decoration: underline;
}
.update-cta-title h3{
    padding-bottom: 0; 
    color: #000000; 
    text-transform: uppercase;
text-align: center;
}
.update-cta-title p{
    font-size: 18px;
color: #000; 
margin-top: 15px;
text-align: justify;
}

/*/////////////////////// Academic Infrastucture css ///////////////////*/
.update-faq-area .section-title p{
    font-size: 17px;
    margin-top: 25px;
    /* line-height: 34px; */
    color: #000;
    text-align: justify;
    font-family: Georgia;
    }
    .update-card-header {
        padding: .5rem 1rem;
        margin-bottom: 0;
        /* background-color: rgba(0,0,0,.03); */
        /* border-bottom: 1px solid rgba(0,0,0,.125); */
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
    .update-faq-wrap .card-header:first-child {
        border-radius: 0;
    }
    .update-faq-wrap .card-body {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: unset;
        padding: 25px 0px;
        color: #000;
        text-align: justify;
        font-family: Georgia;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
    .update-faq-wrap .card-header h2 {
        font-size: unset !important;
    }
    .update-faq-wrap .card {
    border: none;
    border-radius: 4px !important;
    margin-bottom: 15px;
    box-shadow: none;
    overflow: hidden;
    /* background: no-repeat; */
    background-color: #fff;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    }
    .update-faq-wrap .card-header h2 button::after {
        position: absolute;
        content: "\f107";
        top: 5px;
        right: 5px;
        font-size: 26px;
        font-family: "Font Awesome 5 Pro";
        font-weight: 600;
        width: 50px;
        height: 50px;
        text-align: center;
        line-height: 50px;
        color: #ED1E24;
        border-radius: 4px;
         
        }
        
        .update-faq-wrap .card-header h2 button.collapsed::after {
            content: "\f104";
            color: #141b22;
            /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
            font-size: 26px;
            margin-top: 10px;
               /* border: 1px solid #c6c6c6; */
               
        }
    .update-faq-btn {
        font-size: 17px;
        font-weight: 160;
        border: none;
        outline: none;
        cursor: pointer;
        padding: 20px 30px;
          padding-right: 30px;
        width: 100%;
        text-align: left;
        padding-right: 75px;
        background: #fff;
        border: 1px solid #c6c6c6;
        border-radius: 4px;
        }
        .update-faq-btn.collapsed {
            /* background: no-repeat; */
           color: #190a32;
           border: 1px solid #c6c6c6;
           border-radius: 4px;
           box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
           }
.update-card-body{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    text-align: justify;
    padding: 10px;  
}
           /*/////////////////////////// About page Government recognization ///////////////*/

           .update-government-recognization-para{
           font-family: Georgia;
           font-size: 17px;
           
        }
        /*///////////////////////// Slider screen css ////////////////////*/

        .update-services-box07{
            background-color: #000;
                padding: 35px;
                transition: all 0.3s ease 0s;
                height:380px;
                margin-top: 10vh;
                border-radius: 10px;
            }
            
            .update-services-box07:hover,.update-services-box07.active{
                background: #ED1E24;
            }
            .update-services-box07 .sr-contner {
                display: flex;
            }
            .update-services-box07 .sr-contner .icon {
              width: 138px;
              margin-right: 20px;
            }
            .update-services-box07 .sr-contner .text h5{
                font-size:25px;
                color: #fff;
                
            }
            .update-services-box07 .sr-contner .text p{
                margin-bottom:10px;
                color: #fff;
            }
            .update-services-box07 .sr-contner .text a{
                font-family: 'Jost', sans-serif;
                color: #fff;
            }

            /*///////////////////// Financial aid button class //////////////////////*/

            .update-btn {
                display: inline-block;
                margin-bottom: 0;
                font-weight: normal;
                text-align: center;
                justify-content: center;
                vertical-align: middle;
                -ms-touch-action: manipulation;
                touch-action: manipulation;
                cursor: pointer;
                background-image: none;
                border: 1px solid transparent;
                white-space: nowrap;
                padding:10px;
                font-size: 16px;
                line-height: 1.42857143;
                border-radius: 4px;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                background-color: #ED1E24;
                color: #fff;
                margin-top: 20px;
            }
            .update-btn:hover{
                background-color: #000;
                color: #fff;  
            }

            /*/////////////////////// Footer social icon css /////////////////////////*/

            .update-footer-social a {
                font-size: 16px;
                display: inline-block;
                margin-right: 10px;
                width: 40px;
                height: 40px;
                 border-radius: 50%;
                background-color: #ED1E24;
                text-align: center;
                line-height: 40px;
                color: #fff !important;
                }
                .update-footer-social a:hover{    
                  background: #ED1E24;
                    color: #fff !important;
                }

                
.toggle-button {
    position: fixed;
    top: 78%;
    right: 0px;
    /* transform: translateY(-50%) translateX(100%); Initially moved outside the view */
    transition: transform 0.3s ease;
    z-index: 999;
  }
  
  .toggle-button button {
    padding: 5px 16px;
    background-color: #12265a;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transform: rotate(-90deg); /* Rotates the button vertically */
    margin-right: -62px;
  }
  
  .toggle-button.active {
    transform: translateY(-50%) translateX(0); /* Moves the button to the visible position */
  }
  
  .content-toggle {
    position: fixed;
    top: 40%;
    right: 0;
    width: auto; /* Set your preferred width */
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 5px;
    z-index: 998;
    transition: transform 0.3s ease;
    transform: translateX(100%);
  }
  
  .content-toggle.show {
    transform: translateX(0);
  }

  .content-toggle-header {
    display: flex;
    justify-content: end;
    align-items: center;
  }
  
  .close-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    outline: none;
  }
  
  .close-button:hover {
    color: red; /* Change color when hovered */
  }
        
  .marquee-container {
    overflow: hidden;
    white-space: nowrap;
    color: #fff;
}

.marquee-link {
    display: inline-block;
    margin-right: 300px; /* Adjust spacing between links */
    animation: marquee 20s linear infinite; /* Adjust speed of animation */
}

@keyframes marquee {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}

                  
                  

.new_heaad_width .MuiStack-root ul li {
    min-width: initial !important;
}

.new_heaad_width .MuiStack-root > ul > li {
    padding: 10px 4px !important;
}

.qr_code h6 {
    text-align: center;
}

.mvn_imse.qr_code {
    padding: 30px 0;
    min-height: 500px;
}