/* Custom styles*/

/* Bank Account Details */

.page-image {
  margin: 5rem auto;
  width: 50%;
}
.page-image img {
  width: 100%;
}

.titleStripContainer {
  padding: 1rem 2rem;
}
.titleStripContainer h4 {
  font-size: 1.5rem;
  margin-bottom: 0;
}

/*  */

@media (min-width: 768px) {
  .root-style {
    padding-top: 20rem;
  }
}

.vice-chancellor-bio {
  display: flex;
  gap: 2rem;
  padding: 4rem 0;
  
}

@media (max-width: 600px) {
  .vice-chancellor-bio {
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
  }
}

.vice-chancellor-info {
  color: black;
  background-color: white;
  padding: 2rem 1rem;
  border-radius: 10px;
}

.bio-text-wrapper {
  width: 70%;
}

@media (max-width: 600px) {
  .bio-text-wrapper {
    width: 100%;
  }
}

.contact-card-image {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.contact-card-name {
  font-weight: 600;
  text-align: center;
  font-size: 18px;
}

.contact-card-title {
  font-weight: 600;
  text-align: center;
  font-size: 16px;
  margin-bottom: 1rem;
}

.titleStripContainer h4 {
  color: black;
  font-size: 24px;
  margin-bottom: 1rem;
}

li {
  margin-bottom: 0.5rem;
}

.bio-text-wrapper {
    color: black;
}
 
 .application-policy .li-justify {
    color: black;
    margin-bottom: 5rem;
}

.color-theme10 {
  color: #ED1E24;
    background: #ED1E24;
}

.about-content {
  /* text-align: justify; */
}

.s-about-img {
  text-align: center;
}


/* .vc-img {
  width: 75% !important;
} */